import React, { useState } from 'react';
import '../../../lib/css/HomePageCompetitor.css';

const HomePageCompetitor = (props) => {
    const { competitor, raceType } = props;
    const [showScores, setShowScores] = useState(false);

    const handleDisplayScores = () => {
        setShowScores(prev => !prev)
    }
    return (
        <tr>
            <td style={{ padding: '20px' }}>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <img
                        src={competitor.image || "https://x-tron-images.s3.us-east-1.amazonaws.com/1734434622097tron-pic.png"}
                        alt="Profile"
                        style={!competitor.isPro ? {
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            objectFit: 'cover',
                            marginRight: '15px',
                            border: '3px solid #26ffe2',  // Golden border added
                        } : {
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            objectFit: 'cover',
                            marginRight: '15px',
                            border: '3px solid gold',  // Golden border added
                        }}
                    />
                    <img
                        src={`https://flagcdn.com/${competitor.country[0]}.svg`}
                        alt={competitor.country[1]}
                        style={{
                            position: 'absolute',
                            bottom: '0',
                            left: '30px', // Adjust for horizontal alignment
                            width: '25px', // Adjust size as needed
                            height: '15px',
                        }}
                    />
                </div>
                <span onClick={handleDisplayScores} style={{ cursor: 'pointer' }}>
                    {competitor.firstname} {competitor.lastname}
                </span>
                {showScores &&
                    <div className="mobile-only" style={{
                        width: '100%', lineHeight: '0.6em', textAlign: 'left', paddingLeft: '60px', paddingTop: '20px'
                    }}>
                        <p><strong>Swim ({raceType === 'Standard' ? '1.5km' : '0.3km'}):</strong> 00:00</p>
                        <p><strong>Bike ({raceType === 'Standard' ? '40km' : '20km'}):</strong> 00:00</p>
                        <p><strong>Run ({raceType === 'Standard' ? '10km' : '5km'}):</strong> 00:00</p>
                    </div>
                }
            </td>
            {/* Hide this if user is on mobile */}
            <td className="mobile-hide" style={{ padding: '20px', textAlign: 'center', fontSize: '1.4em' }}>
                00:00
            </td>
            <td className="mobile-hide" style={{ padding: '20px', textAlign: 'center', fontSize: '1.4em' }}>
                00:00
            </td>
            <td className="mobile-hide" style={{ padding: '20px', textAlign: 'center', fontSize: '1.4em' }}>
                00:00
            </td>
            {/* End Hide this if user is on mobile */}
            <td style={{ padding: '20px', textAlign: 'right', fontSize: '1.4em', fontWeight: 'bold', verticalAlign: 'top' }}>
                <span style={{ marginRight: '40px' }}>0</span>
            </td>
        </tr>
    )
}

export default HomePageCompetitor