import { createContext, useCallback, useState } from 'react';

export const Context = createContext({});

const ContextProvider = ({ children }) => {
    const API_LINK = process.env.REACT_APP_API_LINK;
    const [email, setEmail] = useState('');
    const [profilePicture, setProfilePicture] = useState('');
    const [type, setType] = useState('')
    const [usersID, setUsersID] = useState('')
    const [viewPage, setViewPage] = useState('home-page');
    const [showAlert, setShowAlert] = useState(false);
    const [alertObject, setAlertObject] = useState({});
    const [isAdjudicator, setIsAdjudicator] = useState(false);

    // User information
    const [dob, setDob] = useState('dd/mm/yyyy');
    const [gender, setGender] = useState('Male');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [deliveryAddressTwo, setDeliveryAddressTwo] = useState('');
    const [city, setCity] = useState('');
    const [postcode, setPostcode] = useState('');
    const [tagNumber, setTagNumber] = useState('');
    const [country, setCountry] = useState([]);
    const [squareCustomerID, setSquareCustomerID] = useState('');

    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [instagram, setInstagram] = useState('');
    const [x, setX] = useState('');
    const [facebook, setFacebook] = useState('');

    const [infoValid, setInfoValid] = useState(false);
    const [emailValidated, setEmailValidated] = useState(true);

    const [event, setEvent] = useState({});
    const [events, setEvents] = useState([]);
    const [competitors, setCompetitors] = useState([]);
    const [countries, setCountries] = useState([]);

    const [showAuth, setShowAuth] = useState(false);
    const [showEventModal, setShowEventModal] = useState(false);
    const [editEventID, setEditEventID] = useState('');
    const [viewEventID, setViewEventID] = useState('');
    const [isLiveEvent, setIsLiveEvent] = useState(true);
    const [showAddCompetitorModal, setShowAddCompetitorModal] = useState(false);
    const [addCompetitorEventID, setAddCompetitorEventID] = useState('');
    const [showMapCompetitorsModal, setShowMapCompetitorsModal] = useState(false);
    const [mapCompetitorsEventID, setMapCompetitorsEventID] = useState('');
    const [mapCompetitorsEventType, setMapCompetitorsEventType] = useState('');
    const [mapAdjudicatorsEventType, setMapAdjudicatorsEventType] = useState('');
    const [showEditCompetitorWeightsModal, setShowEditCompetitorWeightsModal] = useState(false);
    const [editWeightsUser, setEditUserWeightsUser] = useState({});
    const [editUserWeightsEventID, setEditUserWeightsEventID] = useState('');
    const [showEditAdjudicatorModel, setShowEditAdjudicatorModal] = useState(false);
    const [editAdjudicatorEventID, setEditAdjudicatorEventID] = useState('');

    const [showBuyPassModal, setShowBuyPassModal] = useState(false);
    const [showBuyPassEventID, setShowBuyPassEventID] = useState('');

    const [showAddAdjudicatorModal, setShowAddAdjudicatorModal] = useState(false);
    const [addAdjudicatorEventID, setAddAdjudicatorEventID] = useState('');
    const [showCollectWeightsModal, setShowCollectWeightsModal] = useState(false);
    const [collectWeightsEventID, setCollectWeightsEventID] = useState('')
    const [showAddCompetitorScoreModal, setShowAddCompetitorScoreModal] = useState(false);
    const [addCompetitorScoreCompetitorObject, setAddCompetitorScoreCompetitorObject] = useState({});
    const [addCompetitorScoreEventID, setAddCompetitorScoreEventID] = useState('');
    const [showEditProfileModal, setShowEditProfileModal] = useState(false);
    const [showUpdateVirtualScoresModal, setShowUpdateVirtualScoresModal] = useState(false);
    const [updateVirtualScoresEventID, setUpdateVirtualScoresEventID] = useState('');


    const APP_NAME = process.env.REACT_APP_APP_NAME;

    // This function checks to see if the user is in any of the upcoming events
    // And prompts them to enter in their weights if they've not already done so.
    const handleCheckUserDetails = (props) => {
        let { userID, events } = props;
        let dataNeeded = false;
        let eventID = ''
        for (let i = 0; i < events?.length; i++) {
            for (let j = 0; j < events[i]?.competitors.length; j++) {
                if (userID === events[i]?.competitors[j].userID) {
                    // Check if the map is empty
                    eventID = events[i]._id
                    const exerciseWeights = events[i].competitors[j].exercise_weights;
                    if (Object.keys(exerciseWeights).length === 0) {
                        dataNeeded = true;
                        i = events.length;
                    } else {
                        for (let weight of Object.values(exerciseWeights)) {
                            if (weight === 0) {
                                dataNeeded = true;
                                i = events.length;
                            }
                        }
                    }
                }
            }
        }
        if (dataNeeded) {
            // Show the modal to retrieve the users weights.
            setCollectWeightsEventID(eventID)
            setShowCollectWeightsModal(true);
        }
    }

    const checkIsAdjudicator = (eventID) => {
        const event = events.find(e => e._id === eventID);
        return event?.adjudicators.some(adjudicator => adjudicator.userID === usersID) || false;
    }

    const getUserProfile = useCallback(() => {
        fetch(API_LINK + '/profile', {
            credentials: 'include',
            method: 'GET',
            cache: 'no-cache'
        }).then(response => {
            response.json().then(data => {
                if (data !== 'OK') {
                    setFirstname(data.firstname);
                    setEmail(data.email);
                    setLastname(data.lastname);
                    setProfilePicture(data.profilePicture);
                    setType(data.type);
                    setEvents(data.events);
                    setUsersID(data.usersID);
                    handleCheckUserDetails({ userID: data.usersID, events: data.events });
                    setGender(data.gender);
                    setTagNumber(data.tag_number);
                    setDob(data.dob);
                    setDeliveryAddress(data.delivery_address);
                    setDeliveryAddressTwo(data.delivery_address_two);
                    setCity(data.city);
                    setPhoneNumber(data.phone_number);
                    setPostcode(data.postcode);
                    setWeight(data.weight);
                    setHeight(data.height);
                    setX(data.socials.x);
                    setFacebook(data.socials.facebook);
                    setInstagram(data.socials.instagram);
                    setEmailValidated(data.emailValidated);
                    setSquareCustomerID(data.square_customer_id);
                    setCountries(data.countries);
                    setCountry(data.country);
                }
            })
        })
    }, [API_LINK])

    const logout = () => {
        fetch(API_LINK + '/logout', {
            credentials: 'include',
            method: 'POST'
        }).then(response => {
            response.json().then(data => {
                setFirstname('');
                setEmail('');
                setLastname('');
                setProfilePicture('');
                setType('');
                setEvents([]);
                setUsersID('');
                setGender('Male');
                setTagNumber('');
                setDob('');
                setDeliveryAddress('');
                setDeliveryAddressTwo('');
                setCity('');
                setPhoneNumber('');
                setPostcode('');
                setWeight('');
                setHeight('');
                setX('');
                setFacebook('');
                setInstagram('');
                setEmailValidated(false);
                setSquareCustomerID('');
                window.location.reload(); // Or redirect to a login page
            })
        });
    }

    const handleSwitchPage = (page) => {
        setViewPage(page);
    }

    const loseFocus = (event) => {
        if (event.code === 'Enter') {
            event.target.blur();  // This will lose focus on Enter key press
        }
    }

    const userInfoValid = useCallback((userInfo) => {
        const {
            firstname, lastname, phoneNumber, deliveryAddress, deliveryAddressTwo, city,
            postcode, dob, gender
        } = userInfo;
        return firstname?.length > 0 && lastname?.length > 0 && phoneNumber?.length > 0 && deliveryAddress?.length > 0 &&
            postcode?.length > 0 && dob !== 'dd/mm/yyyy' && (gender === 'Male' || gender === 'Female') &&
            deliveryAddressTwo?.length > 0 && city?.length > 0
    }, [])

    const getCompetitorsInfo = useCallback(async () => {
        const response = await fetch(API_LINK + '/get-profile-info', {
            method: 'POST',
            body: JSON.stringify({ userID: usersID }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                if (data.error) {
                    console.log('There was an error: ' + data.error);
                } else {
                    let user = data.user;
                    setEmailValidated(user?.emailValidated);
                    setFirstname(user?.firstname);
                    setLastname(user?.lastname);
                    setPhoneNumber(user?.phone_number);
                    setDeliveryAddress(user?.delivery_address);
                    setPostcode(user?.postcode);
                    setDob(user?.dob);
                    setGender(user?.gender);
                    setProfilePicture(user?.profilePicture);
                    setCountry(user?.country.length === 0 ? ['gb', 'United Kingdom'] : user?.country)
                    setEvents(data.events);
                    setCountries(data.countries);
                    let userValid = userInfoValid({
                        firstname: user?.firstname,
                        lastname: user?.lastname,
                        phoneNumber: user?.phone_number,
                        deliveryAddress: user?.delivery_address,
                        postcode: user?.postcode,
                        dob: user?.dob,
                        gender: user?.gender
                    });
                    setInfoValid(userValid);
                }
            });
        }
    }, [API_LINK, setEvents, usersID, userInfoValid, setDeliveryAddress, setDob, setFirstname, setGender,
        setLastname, setPhoneNumber, setPostcode, setInfoValid]);

    const getEvents = () => {
        fetch(API_LINK + '/get-events', {
            credentials: 'include',
            method: 'GET'
        }).then(response => {
            response.json().then(data => {
                setEvents(data.events);
            })
        })
    }

    return (<Context.Provider value={{
        getUserProfile, logout, handleSwitchPage,
        API_LINK, APP_NAME, loseFocus,
        viewPage, setViewPage,
        showAlert, setShowAlert,
        alertObject, setAlertObject,
        isAdjudicator, setIsAdjudicator,

        firstname, setFirstname,
        lastname, setLastname,
        dob, setDob,
        gender, setGender,
        phoneNumber, setPhoneNumber,
        deliveryAddress, setDeliveryAddress,
        deliveryAddressTwo, setDeliveryAddressTwo,
        city, setCity,
        postcode, setPostcode,
        email, setEmail,
        weight, setWeight,
        height, setHeight,
        instagram, setInstagram,
        x, setX,
        facebook, setFacebook,
        type, setType,
        usersID, setUsersID,
        profilePicture, setProfilePicture,
        tagNumber, setTagNumber,
        country, setCountry,
        squareCustomerID, setSquareCustomerID,

        infoValid, setInfoValid,
        emailValidated, setEmailValidated,

        getEvents,
        event, setEvent,
        events, setEvents,
        countries, setCountries,

        showAuth, setShowAuth,
        showEventModal, setShowEventModal,
        editEventID, setEditEventID,
        viewEventID, setViewEventID,
        isLiveEvent, setIsLiveEvent,
        showAddCompetitorModal, setShowAddCompetitorModal,
        addCompetitorEventID, setAddCompetitorEventID,
        showMapCompetitorsModal, setShowMapCompetitorsModal,
        mapCompetitorsEventID, setMapCompetitorsEventID,
        mapCompetitorsEventType, setMapCompetitorsEventType,
        mapAdjudicatorsEventType, setMapAdjudicatorsEventType,
        showEditCompetitorWeightsModal, setShowEditCompetitorWeightsModal,
        showAddAdjudicatorModal, setShowAddAdjudicatorModal,
        editWeightsUser, setEditUserWeightsUser,
        competitors, setCompetitors,
        editUserWeightsEventID, setEditUserWeightsEventID,
        addAdjudicatorEventID, setAddAdjudicatorEventID,
        showEditAdjudicatorModel, setShowEditAdjudicatorModal,
        editAdjudicatorEventID, setEditAdjudicatorEventID,
        showCollectWeightsModal, setShowCollectWeightsModal,
        collectWeightsEventID, setCollectWeightsEventID,
        showAddCompetitorScoreModal, setShowAddCompetitorScoreModal,
        addCompetitorScoreEventID, setAddCompetitorScoreEventID,
        addCompetitorScoreCompetitorObject, setAddCompetitorScoreCompetitorObject,
        showBuyPassModal, setShowBuyPassModal,
        showBuyPassEventID, setShowBuyPassEventID,

        showUpdateVirtualScoresModal, setShowUpdateVirtualScoresModal,
        updateVirtualScoresEventID, setUpdateVirtualScoresEventID,


        showEditProfileModal, setShowEditProfileModal,


        handleCheckUserDetails, checkIsAdjudicator, getCompetitorsInfo, userInfoValid

    }}>
        {children}
    </Context.Provider>
    )
}

export default ContextProvider;