import React, { useContext, useEffect } from 'react'
import { Context } from '../../../../lib/Context'
// import MapEventGroup from './MapEventGroup';
import Spinner from '../Spinner';
import MapEventAgeGroup from './MapEventAgeGroup';
import MapAdjudicatorCompetitors from './MapAdjudicatorCompetitors';

const ViewEvent = (props) => {
    const {
        filterGenderGroup, filterAgeGroup, handleSetFilterAgeGroups, handleSetFilterGenderGroups,
        competitorObject, setCompetitorObject, ageGroups, getCompetitors, adjudicators, isLoading,
        setIsLoading, handleClearEventInfo
    } = props;

    const {
        API_LINK, viewEventID, events, type, email, isAdjudicator, setIsAdjudicator,
        usersID, event, setEvent
    } = useContext(Context);

    useEffect(() => {
        let isLive = false;
        // Find the selected event
        for (let i = 0; i < events.length; i++) {
            if (events[i]._id === viewEventID) {
                for (let j = 0; j < events[i].adjudicators.length; j++) {
                    if (events[i].adjudicators[j].userID === usersID) {
                        setIsAdjudicator(true);
                    }
                }
                setEvent(events[i]);
                isLive = events[i].isLive;
            }
        }
        // Run getCompetitors on initial render
        getCompetitors(viewEventID);

        // Make this more robust i.e. only show for users.
        if (type !== 'admin' && email && isLive && !isAdjudicator) {
            // Set up an interval to run getCompetitors every 10 seconds
            const interval = setInterval(() => {
                getCompetitors(viewEventID);
            }, 10000); // 10 seconds

            // Cleanup function to clear the interval on component unmount
            return () => clearInterval(interval);
        }

    }, [
        viewEventID, events, API_LINK, setCompetitorObject, getCompetitors, email, type, setIsAdjudicator,
        usersID, isAdjudicator, setEvent
    ]);



    return (
        competitorObject?.length === 0 ?
            <Spinner />
            :
            (competitorObject?.length !== 0 && !isAdjudicator ?
                competitorObject?.map((genders, index) => {
                    return <MapEventAgeGroup key={"map-event-gender-" + index}
                        genders={genders}
                        groupIndex={index}
                        ageGroups={ageGroups}
                        event={event}
                        adjudicators={adjudicators}
                        handleSetFilterAgeGroups={handleSetFilterAgeGroups}
                        handleSetFilterGenderGroups={handleSetFilterGenderGroups}
                        filterAgeGroup={filterAgeGroup}
                        filterGenderGroup={filterGenderGroup}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        getCompetitors={getCompetitors}
                    />
                })
                : competitorObject?.length !== 0 &&
                <MapAdjudicatorCompetitors competitors={competitorObject} event={event}
                    handleClearEventInfo={handleClearEventInfo} />
            )
    )
}

export default ViewEvent