import React, { useContext, useEffect, useState } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTrendUp, faPersonRunning, faScaleUnbalanced, faTicket } from '@fortawesome/free-solid-svg-icons';
import { Context } from '../../../../lib/Context';
import Countdown from 'react-countdown';

const VirtualElement = (props) => {
    const { event, handleViewEvent, handleShowMapCompetitorsModal, getCompetitors } = props;
    const {
        setShowBuyPassModal, setShowBuyPassEventID, showBuyPassEventID, events, usersID, type, email,
        setIsLiveEvent, setShowUpdateVirtualScoresModal, setUpdateVirtualScoresEventID, setIsAdjudicator
    } = useContext(Context);
    const [userInEvent, setUserInEvent] = useState(false); // Is this user already paid up and in this event?
    const [eventState, setEventState] = useState(0); // 0 = Event hasn't started, 1 = Event has started, 2 = Event has finished
    const [timeLeft, setTimeLeft] = useState(0);
    let eventID = event._id;

    useEffect(() => {
        getCompetitors(eventID);
        for (let i = 0; i < events.length; i++) {
            if (events[i]._id === event._id) {
                let start = events[i].start_time_virtual;
                let end = events[i].end_time_virtual;

                // Check to see if event has started
                const startTime = new Date(start); // Convert start to a Date object
                const endTime = new Date(end); // Convert end to a Date object
                const currentTime = new Date(); // Get current time

                // Check if event has started
                if (currentTime >= startTime && currentTime <= endTime) {
                    setEventState(1); // Event is ongoing
                } else if (currentTime < startTime) {
                    setEventState(0); // Event hasn't started yet
                    // Calculate time left in seconds before event starts
                    const timeLeftInSeconds = Math.floor((startTime - currentTime)); // Convert milliseconds to seconds
                    setTimeLeft(timeLeftInSeconds); // Set time left in seconds
                } else if (currentTime > endTime) {
                    setEventState(2); // Event has finished
                }

                for (let j = 0; j < events[i].virtual_competitors.length; j++) {
                    if (events[i].virtual_competitors[j].userID === usersID) {
                        setUserInEvent(true);
                    }
                }
            }
        }
    }, [events, showBuyPassEventID, usersID, event._id, getCompetitors, eventID, eventState])

    const handleShowBuyPassModal = () => {
        setShowBuyPassModal(true);
        setShowBuyPassEventID(eventID);
        setIsLiveEvent(false); // Because we are in a virtual element
    }

    const handleViewVirtualEvent = () => {
        setIsAdjudicator(false);
        setIsLiveEvent(false);
        handleViewEvent(event._id);
    }

    const handleShowUpdateEventScoresModal = () => {
        setShowUpdateVirtualScoresModal(true);
        setUpdateVirtualScoresEventID(event._id);
    }

    const handleCountdownExpired = () => {
        setEventState(1); // Event has started
    }

    return (
        <div>
            {/* {event.eventType === 'Hybrid' &&
                <p style={{ marginBottom: '0px' }}><strong>Virtual Event</strong></p>
            } */}
            <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
                {event.virtual_competitors.length > 0 && type === 'admin' &&
                    <Col>
                        <button className="green-glow-button-large" style={{
                            width: '100%', marginLeft: 'auto',
                            marginRight: 'auto', marginTop: '10px'
                        }}
                            variant="info" onClick={() => { handleShowMapCompetitorsModal({ eventID, type: "Virtual" }) }}>
                            <FontAwesomeIcon icon={faPersonRunning} style={{ marginRight: '5px' }} /> Edit Competitors
                        </button>
                    </Col>
                }
                {userInEvent ?
                    <Col xs={12} sm={6}>
                        <button className="green-glow-button-large" style={{
                            width: '100%', marginLeft: 'auto',
                            marginRight: 'auto', marginTop: '10px'
                        }}
                            variant="info" onClick={eventState === 1 ? handleShowUpdateEventScoresModal : () => { }}>
                            {eventState === 0 ?
                                <div style={{ padding: '0px', margin: '0px', fontWeight: 'bold' }}>
                                    EVENT GOES LIVE IN <Countdown date={Date.now() + timeLeft} onComplete={handleCountdownExpired} />
                                </div>
                                : eventState === 1 ?
                                    <><FontAwesomeIcon icon={faScaleUnbalanced} style={{ marginRight: '20px' }} /> Update Scores</>
                                    :
                                    'Event Finished'}
                        </button>
                    </Col>
                    : type !== 'admin' && email &&
                    <Col xs={12} sm={6}>
                        <button className="green-glow-button-large" style={{
                            width: '100%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px'
                        }}
                            variant="info" onClick={() => { handleShowBuyPassModal({ type: 'Virtual' }) }}>
                            <FontAwesomeIcon icon={faTicket} /> Buy {event.eventType === 'Hybrid' ? 'Season' : 'Event'} Pass
                        </button>
                    </Col>
                }
                {type === 'admin' && email ?
                    <Col xs={12}>
                        <button className="green-glow-button-large" style={{
                            width: '100%', marginLeft: 'auto',
                            marginRight: 'auto', marginTop: '10px'
                        }}
                            variant="info" onClick={handleViewVirtualEvent}>
                            <FontAwesomeIcon icon={faArrowTrendUp} /> View Leaderboard
                        </button>
                    </Col>
                    : email ?
                        <Col xs={12} sm={6}>
                            <button className="green-glow-button-large" style={{
                                width: '100%', marginLeft: 'auto',
                                marginRight: 'auto', marginTop: '10px'
                            }}
                                variant="info" onClick={eventState === 1 ? handleViewVirtualEvent : () => { }}>
                                <FontAwesomeIcon icon={faArrowTrendUp} /> View Leaderboard
                            </button>
                        </Col>
                        :
                        <Col xs={12}>
                            <button className="green-glow-button-large" style={{
                                width: '100%', marginLeft: 'auto',
                                marginRight: 'auto', marginTop: '10px'
                            }}
                                variant="info" onClick={eventState === 1 ? handleViewVirtualEvent : () => { }}>
                                <FontAwesomeIcon icon={faArrowTrendUp} /> View Leaderboard
                            </button>
                        </Col>
                }
            </Row>
        </div>
    )
}

export default VirtualElement