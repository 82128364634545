import React, { useContext, useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import { Context } from '../../../../lib/Context';

const PaymentForm = (props) => {
    const { API_LINK, usersID, setEvents, isLiveEvent } = useContext(Context);
    const { handleClose, eventID } = props;
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsProcessing(true);

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${window.location.origin}/completion`,
            },
            redirect: "if_required"
        });

        if (error) {
            setMessage(error.message || "Unknown error occurred!")
        } else if (paymentIntent && paymentIntent.status === "succeeded") {
            // TODO: this is where we run the logic once the payment is approved!
            const response = await fetch(API_LINK + '/make-stripe-payment', {
                method: 'POST',
                body: JSON.stringify({ userID: usersID, eventID, isLiveEvent, paymentID: paymentIntent.id }),
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });
            if (response.ok) {
                response.json().then((data) => {
                    // Do stuff here
                    setEvents(data.events);
                    handleClose();
                });
            }
            setMessage("Payment status: " + paymentIntent.status)
        } else {
            setMessage("Unexpected state");
        }

        setIsProcessing(false);
    };


    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
            <button
                className="green-glow-button"
                id="submit"
                disabled={isProcessing || !stripe || !elements}
                style={{ marginTop: '30px', width: '100%', padding: '10px' }}
            >
                <span id="button-text">
                    {isProcessing ? "Processing ... " : "Pay now"}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message &&
                <div id="payment-message">{message}</div>
            }
            <button className="white-glow-button" style={{
                marginTop: '10px', width: '100%', padding: '10px'
            }} id="submit"
                onClick={handleClose}>
                <span id="button-text">
                    Cancel
                </span>
            </button>
        </form>

    )
}

export default PaymentForm