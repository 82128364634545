import React, { useCallback, useContext, useEffect, useState } from 'react'
import { motion as m } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/esm/Container';
import '../../lib/css/App.css';
// eslint-disable-next-line
import { Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import ViewEvent from './PageElements/EventElements/ViewEvent';
import MapEvent from './PageElements/EventElements/MapEvent';
import { Context } from '../../lib/Context';
import FilterAgeGroupsDropdown from './PageElements/EventElements/FilterAgeGroupsDropdown';


const EventsPage = () => {
    const {
        API_LINK, setViewPage, setShowEventModal, viewEventID, setViewEventID, type, events, setEvent, setIsAdjudicator,
        isAdjudicator, setEvents, getEvents, isLiveEvent, usersID, dob, gender
    } = useContext(Context);
    const [filterAgeGroup, setFilterAgeGroup] = useState({ low_age: 12, high_age: 13 });
    const [filterGenderGroup, setFilterGenderGroup] = useState('Male');
    const [ageGroups, setAgeGroups] = useState([]);
    const [competitorObject, setCompetitorObject] = useState([]);
    const [eventType, setEventType] = useState('Virtual Events');
    const [adjudicators, setAdjudicators] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();

    function calculateAge(dateString) {
        const birthDate = new Date(dateString);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        // Adjust age if the birthday hasn't occurred yet this year
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }

    useEffect(() => {
        if (location.pathname === '/events') {
            setViewPage('events-page');
        }

        // Find the correct event and build the age groups array
        const foundAges = events?.find(event => event._id === viewEventID)?.age_groups || [];
        setAgeGroups(foundAges);

        // if (foundAges.length > 0) {
        //     setFilterAgeGroup({ low_age: foundAges[0].low_age, high_age: foundAges[0].high_age });
        // }

        setFilterGenderGroup(gender);
        if (dob) {
            const age = calculateAge(dob);

            // Handle age group filtering directly
            for (let i = 0; i < foundAges.length; i++) {
                if (age >= foundAges[i].low_age && age <= foundAges[i].high_age) {
                    setFilterAgeGroup({
                        low_age: foundAges[i].low_age,
                        high_age: foundAges[i].high_age
                    });
                    break; // Exit loop after finding the group
                }
            }
        }
    }, [location.pathname, setViewPage, events, viewEventID, dob, gender]);

    const handleSetFilterAgeGroups = (group) => {
        setFilterAgeGroup(group);
    }

    const handleSetFilterGenderGroups = (group) => {
        setFilterGenderGroup(group);
    }

    const handleClearEventInfo = () => {
        setViewEventID('');
        if (isAdjudicator) {
            fetch(API_LINK + '/get-events', {
                credentials: 'include',
                method: 'GET'
            }).then(response => {
                response.json().then(data => {
                    setEvents(data.events);
                })
            })
        }
        setIsAdjudicator(false);
        setEvent({});
    }

    // eslint-disable-next-line
    const handleSwitchType = async (event) => {
        await getEvents();
        setIsAdjudicator(false);
        setEventType(event);
    }

    const getCompetitors = useCallback(async (eventID) => {
        setIsLoading(true);
        let response = await fetch(API_LINK + '/get-competitor-object', {
            method: 'POST',
            body: JSON.stringify({ eventID, isLiveEvent, isAdjudicator, userID: usersID }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                if (data.error) {
                    console.log('There was an error: ' + data.error);
                } else {
                    setCompetitorObject(data.competitors);
                    setAgeGroups(data.age_groups);
                    if (isLiveEvent) {
                        setAdjudicators(data.adjudicators);
                    }
                    setIsLoading(false);
                }
            });
        }
    }, [API_LINK, setCompetitorObject, isLiveEvent, isAdjudicator, usersID, setAgeGroups]);




    return (
        <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: 'easeOut' }}>
            <div className="gradient-bg" style={{ minHeight: '100vh', fontFamily: "Urbanist", marginTop: '-0px' }}>
                <Container style={{ paddingTop: '20px', paddingBottom: '20px', color: 'white' }}>
                    <Row>
                        {/* {!viewEventID &&
                            <Col>
                                <DropdownButton
                                    id="custom-dropdown"
                                    title={eventType}
                                    onSelect={handleSwitchType}
                                    variant="info"
                                    style={{ width: '100%', marginBottom: '10px', float: 'right' }}
                                    className="full-width-dropdown"
                                >
                                    <Dropdown.Item eventKey={'Seasons'} style={{ width: '100%' }}>Seasons</Dropdown.Item>
                                    <Dropdown.Item eventKey={"Live Events"}>Live Events</Dropdown.Item>
                                    <Dropdown.Item eventKey={"Virtual Events"}>Virtual Events</Dropdown.Item>
                                    <Dropdown.Item eventKey={"All Events"}>All Events</Dropdown.Item>
                                </DropdownButton>

                            </Col>
                        } */}
                        {type === 'admin' && !viewEventID &&
                            <Col xs={12} md={4}>

                                <button className="white-glow-button" onClick={() => setShowEventModal(true)} type="submit"
                                    style={{
                                        float: 'right', marginBottom: '20px', paddingTop: '5px',
                                        paddingBottom: '5px', width: '100%'
                                    }}>
                                    <FontAwesomeIcon icon={faCalendarPlus} style={{ marginRight: '10px' }} /> Add New Event
                                </button>

                            </Col>
                        }
                    </Row>
                    {!viewEventID ?
                        <>
                            <MapEvent eventType={eventType} getCompetitors={getCompetitors} />
                        </>
                        :
                        <>
                            <div style={{ width: '100%', marginBottom: '60px' }}>
                                {!isAdjudicator &&
                                    <>
                                        <button className="green-glow-button" onClick={() => handleClearEventInfo('')}
                                            type="submit" style={{ float: 'left', marginBottom: '20px' }}>
                                            <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faArrowLeft} /> Back to Events
                                        </button>
                                        <FilterAgeGroupsDropdown
                                            ageGroups={ageGroups}
                                            filterAgeGroup={filterAgeGroup}
                                            handleSetFilterAgeGroups={handleSetFilterAgeGroups}
                                        />
                                        <Dropdown style={{ float: 'right', marginRight: '30px' }}>
                                            <Dropdown.Toggle variant="light" id="custom-dropdown">
                                                {filterGenderGroup}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => {
                                                    handleSetFilterGenderGroups('Female')
                                                }}>Female</Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    handleSetFilterGenderGroups('Male')
                                                }}>Male</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div style={{ float: 'right', marginRight: '20px', paddingTop: '6px', color: '#26ffe1' }}>
                                            <span >Select Competitor Group:</span>
                                        </div>
                                    </>
                                }
                            </div>
                            <ViewEvent
                                filterGenderGroup={filterGenderGroup}
                                setFilterGenderGroup={setFilterGenderGroup}
                                filterAgeGroup={filterAgeGroup}
                                setFilterAgeGroup={setFilterAgeGroup}
                                handleSetFilterAgeGroups={handleSetFilterAgeGroups}
                                handleSetFilterGenderGroups={handleSetFilterGenderGroups}
                                competitorObject={competitorObject}
                                setCompetitorObject={setCompetitorObject}
                                ageGroups={ageGroups}
                                setAgeGroups={setAgeGroups}
                                getCompetitors={getCompetitors}
                                adjudicators={adjudicators}
                                setAdjudicators={setAdjudicators}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                handleClearEventInfo={handleClearEventInfo}
                            />
                        </>
                    }
                </Container>
            </div>
        </m.div >
    )
}

export default EventsPage