import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../lib/Context'
import { Col, Form, Row } from 'react-bootstrap';

const SelectCountry = (props) => {
    const { setViewSetCountry } = props;
    const { API_LINK, countries, usersID, setCountry } = useContext(Context);
    const [filteredCountries, setFilteredCountries] = useState([]);

    useEffect(() => {
        setFilteredCountries([...countries])
    }, [countries]);

    const handleChangeCountry = async (countryCode) => {
        // This is where we will send a request to update the users country
        const response = await fetch(API_LINK + '/update-users-country', {
            method: 'POST',
            body: JSON.stringify({ userID: usersID, countryCode }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                if (data.error) {
                    console.log('There was an error: ' + data.error);
                } else {
                    setCountry(data.country);
                    setViewSetCountry(false);
                }
            });
        }

    }

    const handleFilterCountries = (event) => {
        const inputText = event.target.value.toLowerCase(); // Convert input to lowercase for case-insensitive filtering
        if (inputText !== '') {
            let foundCountries = []
            for (let i = 0; i < countries.length; i++) {
                if (countries[i][1].toLowerCase().includes(inputText)) {
                    foundCountries.push(countries[i])
                }
            }
            setFilteredCountries(foundCountries);
        } else {
            setFilteredCountries(countries)
        }

    }

    return (
        <>
            <Form.Control
                autoComplete="off"
                type="text"
                id="search-countries"
                aria-describedby="search-help-block"
                placeholder="Search for country"
                onChange={handleFilterCountries}
                style={{ marginTop: '15px', marginBottom: '20px' }}
            />
            {filteredCountries?.length > 0 && filteredCountries.map((country) => {
                return <Row key={'map-country-' + country[0]} style={{
                    marginBottom: '20px', cursor: 'pointer', backgroundColor: '#231c59', padding: '10px', borderRadius: '10px', width: '80%',
                    marginLeft: 'auto', marginRight: 'auto'
                }}
                    onClick={() => { handleChangeCountry(country[0]) }}>
                    <Col xs={3}>
                        <img src={`https://flagcdn.com/${country[0]}.svg`} style={{
                            width: '30px', height: 'auto', float: 'right'
                        }} alt={country[1]} />
                    </Col>
                    <Col xs={9}>
                        {country[1]}
                    </Col>
                </Row>
            })}

        </>


    )
}

export default SelectCountry