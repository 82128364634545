import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../lib/Context';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import Alert from 'react-bootstrap/Alert';
import SpinnerObject from '../Pages/PageElements/Spinner';
import { motion as m } from 'framer-motion';

const UpdateVirtualEventScoresModal = () => {
    // eslint-disable-next-line
    const { API_LINK, showUpdateVirtualScoresModal, setShowUpdateVirtualScoresModal, usersID,
        // eslint-disable-next-line
        updateVirtualScoresEventID, setUpdateVirtualScoresEventID, events, setEvents
    } = useContext(Context)
    const [exercises, setExercises] = useState(new Map());
    const [exerciseScores, setExerciseScores] = useState(new Map());
    const [uploadProof, setUploadProof] = useState(false); // State to display upload area
    const [uploadProofSlug, setUploadProofSlug] = useState(''); // The exercise that is being scored
    const [proofRequired, setProofRequired] = useState(false); // Used to determine if the upload proof button is displayed.
    const [busyUploading, setBusyUploading] = useState(false); // Used to show the spinner when the image is being uploaded to the server.

    // eslint-disable-next-line
    const [files, setFiles] = useState([])
    // eslint-disable-next-line
    const competitorID = usersID;
    // eslint-disable-next-line
    const eventID = updateVirtualScoresEventID;
    // eslint-disable-next-line
    const isLiveEvent = false;

    useEffect(() => {
        if (events) {
            for (let i = 0; i < events.length; i++) {
                if (events[i]._id === updateVirtualScoresEventID) {
                    for (let j = 0; j < events[i].virtual_competitors.length; j++) {
                        if (events[i].virtual_competitors[j].userID === usersID) {
                            setExerciseScores({ ...events[i].virtual_competitors[j].exercise_scores })
                        }
                    }
                    let eventExercises = []
                    for (let j = 0; j < events[i].exercises.length; j++) {
                        eventExercises.push(events[i].exercises[j])
                    }
                    setExercises([...eventExercises]);
                }
            }
        }
    }, [updateVirtualScoresEventID, events, usersID]);

    const handleSetScores = (event) => {
        const slug = event.target.name;
        let rawScore = event.target.value.replace(/:/g, ""); // Get raw input as string
        rawScore = rawScore.replace(/^0+/, "");
        setUploadProofSlug(slug);

        if (/^\d*$/.test(rawScore)) { // Only allow numeric input
            const newScores = { ...exerciseScores };
            newScores[slug] = parseInt(rawScore) || 0; // Store raw input as a string
            setExerciseScores(newScores);
            setProofRequired(true);
        }
    };

    const toggleUploadProof = (slug) => {
        if (uploadProof) {
            setUploadProofSlug('');
        } else {
            setUploadProofSlug(slug);
        }
        if (uploadProofSlug !== '' && proofRequired) {
            setUploadProof(!uploadProof);
        }
    }

    const handleClose = () => {
        setUpdateVirtualScoresEventID('');
        setShowUpdateVirtualScoresModal(false);
        setTimeout(() => {
            setUploadProof(false);
            setUploadProofSlug('');
            setProofRequired(false);
        }, "500");

    };

    const updateCompetitorScores = async (file) => {
        // File is the list of images that were uploaded to s3. We only need the 0th item.
        // eslint-disable-next-line
        const scores = { ...exerciseScores }
        // eslint-disable-next-line
        const slug = uploadProofSlug;
        // eslint-disable-next-line
        const photo = file[0];

        setUploadProofSlug('');
        setProofRequired(false);
        setUploadProof(false);
        const response = await fetch(API_LINK + '/update-competitor-score', {
            method: 'POST',
            body: JSON.stringify({
                competitorID, eventID, exercise_scores: scores, isLiveEvent, slug, photo
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                setEvents(data.events);
                setBusyUploading(false);
            });
        }
    }

    const formatTime = (input) => {
        // Ensure input is a string, pad to 6 characters (if it's shorter)
        const paddedInput = String(input).padStart(6, '0');

        // Extract hours, minutes, seconds
        const hours = parseInt(paddedInput.slice(0, 2), 10);
        const minutes = parseInt(paddedInput.slice(2, 4), 10);
        const seconds = parseInt(paddedInput.slice(4, 6), 10);

        // Return the formatted time in HH:MM:SS
        return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const uploadImages = async (pic) => {
        if (pic.length === 0) {
            alert('Please select an image!')
            return;
        }
        const formData = new FormData();
        for (let i = 0; i < pic.length; i++) {
            formData.append('images', pic[i]);
        }

        const response = await fetch(process.env.REACT_APP_BACKEND_LINK + '/image/upload', {
            method: 'POST',
            body: formData
        });
        const data = await response.json();
        let images = [];
        const savedFiles = data.files;
        for (let i = 0; i < savedFiles.length; i++) {
            images.push(savedFiles[i].key);
        }
        await updateCompetitorScores(images);
    }

    const onImageChange = (event) => {
        setBusyUploading(true);
        let imageFiles = event.target.files;
        setFiles(imageFiles);
        uploadImages(imageFiles);
    }

    return (
        <>
            <Modal show={showUpdateVirtualScoresModal} onHide={handleClose} className="modal-dark">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className="glow-text" style={{
                            fontFamily: 'Neon', color: '#26ffe2', fontSize: '2em', lineHeight: '0.6em'
                        }}>
                            {uploadProof ? `Upload Proof` : 'Competitor Scores'}
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {busyUploading ?
                        <>
                            <SpinnerObject />
                        </>
                        :
                        <>
                            {uploadProof ?
                                <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: 'easeOut' }}>
                                    <div>
                                        <div style={{ marginBottom: '20px' }}>
                                            Please upload photo evidence of your <strong>{uploadProofSlug}</strong> score (that clearly
                                            shows your time of {formatTime(exerciseScores[uploadProofSlug])}) before we can save it!
                                        </div>
                                        <form style={{ marginBottom: '30px' }}>
                                            <input onChange={onImageChange} type='file' accept='image/*' alt='Image upload' />
                                        </form>
                                        <div style={{ marginBottom: '20px' }}>
                                            <Alert variant={'danger'}>
                                                NOTE: If you navigate away from this window your score will not be saved but you can update
                                                your score at any time.
                                            </Alert>
                                        </div>
                                    </div>
                                </m.div>
                                :
                                <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: 'easeOut' }}>
                                    <p>
                                        Please keep us updated on your progress. Put in your scores below.
                                    </p>
                                    {exercises?.length > 0 &&
                                        exercises.map((exercise, index) => {
                                            let slug = exercise.name.toLowerCase().replace(/\s+/g, '_');
                                            let exerciseType = exercise.type;
                                            if (exercise.type !== 'Time') {
                                                return (
                                                    <Row
                                                        key={'scores-for-' + slug + updateVirtualScoresEventID}
                                                        style={{ marginBottom: '20px' }}
                                                    >
                                                        <Col style={{ textAlign: 'right', paddingTop: '7px' }} xs={5}>
                                                            <h6>{exercise.name} ({
                                                                exerciseType === "Weights" ? exercise.reps : exercise.distance
                                                            } {
                                                                    exerciseType === "Weights" ? "reps" : "km"
                                                                })</h6>
                                                        </Col>
                                                        <Col xs={4}>
                                                            {/* Input for raw score */}
                                                            <Form.Control
                                                                type="text"
                                                                name={slug}
                                                                value={(exerciseType === "Weights" ? exerciseScores[slug] : formatTime(exerciseScores[slug]))} // Show raw value
                                                                onChange={handleSetScores}
                                                                onBlur={() => { toggleUploadProof(slug) }}
                                                                placeholder={
                                                                    exercise.type === 'Weights'
                                                                        ? 'kg'
                                                                        : exercise.type === 'CV' && 'mins:secs'
                                                                }
                                                            />
                                                        </Col>
                                                        <Col xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {uploadProofSlug === slug && proofRequired &&
                                                                <button className="green-glow-button" style={{ float: 'left' }}>
                                                                    <FontAwesomeIcon style={{ cursor: 'pointer', marginRight: '10px' }} icon={faCamera} /> Proof
                                                                </button>
                                                            }
                                                        </Col>
                                                    </Row>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}

                                    <p><i>NOTE: Please be aware that the value for weights is in 'kg' and value for time is in 'hh:mm:ss' i.e. 1 hr 21 mins and 34 secs is 01:21:34.</i></p>

                                </m.div>
                            }
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="white-glow-button float-left" style={{ float: 'left' }} onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UpdateVirtualEventScoresModal