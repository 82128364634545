import Spanish from '../es.json';
import German from '../de.json';
import French from '../fr.json';
import Dutch from '../nl.json';
import English from '../en.json'; // Default language

export default function getLanguage(locale) {
    switch (locale) {
        case 'es':
            return Spanish;
        case 'de':
            return German;
        case 'fr':
            return French;
        case 'nl':
            return Dutch;
        default:
            return English;
    }
}