import { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './lib/css/App.css';
import HomePage from './Elements/Pages/HomePage';
import { Context } from './lib/Context';
import NavBar from './Elements/Navigation/NavBar';
import Modals from './Elements/Modals/Modals';
import EventsPage from './Elements/Pages/EventsPage';
import Page404 from './Elements/Pages/Page404';
import Footer from './Elements/Pages/PageElements/Footer';
// import { IntlProvider } from 'react-intl';
import getLanguage from './lang/functions/getLanguage';
import { LanguageProvider } from './lang/LanguageContext';


const App = () => {
  const { getUserProfile, showAuth, setShowAuth } = useContext(Context);
  const [showAccount, setShowAccount] = useState(false);
  const locale = navigator.language;
  // eslint-disable-next-line 
  const [lang, setLang] = useState({});

  useEffect(() => {
    setLang(getLanguage(locale));
    getUserProfile();
  }, [getUserProfile, locale]);

  return (
    // <IntlProvider locale={locale} messages={lang}>
    <LanguageProvider>
      <Router>
        <NavBar setShowAccount={setShowAccount} />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        <Modals
          showAuth={showAuth} setShowAuth={setShowAuth}
          showAccount={showAccount} setShowAccount={setShowAccount}
        />
        <Footer />
      </Router >
    </LanguageProvider>
    // </IntlProvider>
  );
}

export default App;
