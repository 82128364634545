import { useLanguage } from '../lang/LanguageContext';

export const useTranslation = () => {
    const { language } = useLanguage();

    // If language is undefined, return the key itself as fallback
    const t = (key) => {
        return language && language[key] ? language[key] : key;
    };

    return { t };
};