import React, { useContext, useEffect } from 'react';
import { motion as m } from 'framer-motion'
import { useLocation } from 'react-router-dom';
import { Context } from '../../lib/Context';
import '../../lib/css/HomePage.css';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonBiking } from '@fortawesome/free-solid-svg-icons';
import HomePageLeaderboard from './PageElements/HomePageLeaderboard';
import Countdown from 'react-countdown';
import { useTranslation } from '../../hooks/useTranslation';

const HomePage = () => {
    const { viewPage, setViewPage, email, setShowAuth } = useContext(Context);
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        if (location.pathname === '/events' && !email) {
            window.location.href = "http://localhost:3000"
        }
        if (location.pathname === '*') {
            setViewPage('home');
        }
    }, [location.pathname, setViewPage, email]);

    const eventType = ['Standard', 'Sprint']

    return (
        viewPage === 'home-page' &&
        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, ease: 'easeOut' }}
            className="parallax-bg"
            style={{ minHeight: '84vh' }}
        >
            <Container style={{ marginTop: '0px', minHeight: '84vh', display: 'flex', alignItems: 'center' }}>
                <Row>
                    <Col lg={7}>
                        <h1 className='glow-text' style={{
                            fontFamily: 'Neon', color: '#26ffe2', fontSize: '3.8em', lineHeight: '0.7em',
                            textTransform: 'uppercase', textAlign: 'center', marginTop: '30px'
                        }}>
                            {t("home.title")}
                        </h1>
                        <p style={{ color: 'white', fontSize: '1.2em', marginTop: '30px' }}>
                            {t("home.hero-1")}
                        </p>
                        <p style={{ color: 'white', fontSize: '1.2em', marginTop: '30px' }}>Get ranked worldwide and re-enter your results as
                            {t("home.hero-2")}
                        </p>
                        <button className="green-glow-button-large" style={{ marginTop: '20px', marginBottom: '30px' }}
                            onClick={() => { setShowAuth(true) }}>
                            <span style={{ marginRight: '10px', fontSize: '1.3em' }}>
                                <strong>
                                    {t("home.register.button")}
                                </strong>
                            </span>
                            <FontAwesomeIcon icon={faPersonBiking} />
                        </button>
                    </Col>
                    <Col lg={5} style={{ paddingTop: '30px', paddingBottom: '100px' }}>
                        <div class="video-container">
                            <iframe
                                src="https://www.youtube.com/embed/D3ygvrxeuEM?autoplay=1&loop=1&playlist=D3ygvrxeuEM&controls=0"
                                title="YouTube video player"
                                frameborder="0"
                                allow="autoplay; loop"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen
                            ></iframe>
                        </div>
                        <div style={{ width: '100%', textAlign: 'center', marginTop: '33px', color: 'white' }}>
                            {t("home.launching.text")}
                            <h1 className='glow-text' style={{
                                fontFamily: 'Neon', fontSize: '3.8em', lineHeight: '0.7em',
                                textTransform: 'uppercase', textAlign: 'center', color: '#26ffe2'
                            }}>
                                <Countdown date={new Date(2025, 1, 1, 0, 1).getTime()} />
                            </h1>
                        </div>
                    </Col>
                </Row>

            </Container>
            <Container style={{ paddingBottom: '100px' }}>
                {eventType.map((type, index) => (
                    <div style={{ marginBottom: '100px' }}>
                        <h1 className='glow-text' style={{
                            fontFamily: 'Neon', color: '#26ffe2', fontSize: '3.8em', lineHeight: '0.7em',
                            textTransform: 'uppercase', textAlign: 'center', marginBottom: '30px'
                        }}>
                            {type}
                        </h1>
                        <Row>
                            <Col xs={12}>
                                <HomePageLeaderboard raceType={type} />
                            </Col>
                        </Row>
                    </div>
                ))}

            </Container>
        </m.div>
    )
}

export default HomePage