import React from 'react'

const UserImage = (props) => {
    const { width, height, profilePicture } = props;

    return (
        <img
            src={profilePicture ? profilePicture : "https://x-tron-images.s3.us-east-1.amazonaws.com/1734434622097tron-pic.png"}
            alt="Profile"
            style={{
                width: width,
                height: height,
                borderRadius: '50%',
                objectFit: 'cover',
                marginTop: '20px'
            }}
        />
    )
}

export default UserImage