import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {

    const containerStyles = {
        width: '100%',
        minHeight: '15vh', // Reduced height
        backgroundColor: '#13066C',
        color: 'white',
    };

    const rowStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        paddingTop: '30px',
        paddingBottom: '30px'
    };

    const colStyles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '10px'
    };

    const linkStyles = {
        textDecoration: 'none',
        color: 'white',
    };


    return (
        <div style={containerStyles}>
            <Row style={rowStyles}>
                <Col style={colStyles} xs={6} xl={2}>
                    <a style={linkStyles} href="https://x-tronlive.com/" target="_blank" rel="noreferrer">
                        X-TRON {new Date().getFullYear()}
                    </a>
                </Col>
                <Col style={colStyles} xs={6} xl={2}>
                    <a style={linkStyles} href="tel:+447418626448">
                        <FontAwesomeIcon style={{ color: '#26ffe2', marginRight: '5px' }} icon={faWhatsapp} />
                        +447418 626 448
                    </a>
                </Col>
                <Col style={colStyles} xs={6} xl={2}>
                    <a style={linkStyles} href="https://x-tronlive.com/contact-us-faq/" target="_blank" rel="noreferrer">
                        Contact Us / FAQ
                    </a>
                </Col>
                <Col style={colStyles} xs={6} xl={2}>
                    <a style={linkStyles} href="https://x-tronlive.com/tri-vr-terms-and-conditions/" target="_blank" rel="noreferrer">
                        Terms & Conditions
                    </a>
                    <a style={linkStyles} href="https://x-tronlive.com/privacy-policy/" target="_blank" rel="noreferrer">
                        Privacy Policy
                    </a>
                </Col>
                <Col style={colStyles} xs={6} xl={2}>
                    <a href="https://www.facebook.com/profile.php?id=61557423500606" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon style={{ color: '#26ffe2', fontSize: '1em', marginRight: '10px' }} icon={faFacebook} />
                    </a>
                    <a href="https://www.instagram.com/xtronlive/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon style={{ color: '#26ffe2', fontSize: '1em', marginRight: '10px' }} icon={faInstagram} />
                    </a>
                    <a href="https://www.tiktok.com/@xtronlive" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon style={{ color: '#26ffe2', fontSize: '1em', marginRight: '10px' }} icon={faTiktok} />
                    </a>
                </Col>
                <Col style={colStyles} xs={6} xl={2}>
                    <a style={linkStyles} href="https://x.com/4igeek" target="_blank" rel="noreferrer">
                        Created By <span style={{ color: '#26ffe2' }}>DigitalHQ</span>
                    </a>
                </Col>
            </Row>
        </div>
    )
}


export default Footer