import { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../lib/Context';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import CompulsoryProfileInfo from './ModalElements/CompulsoryProfileInfo';
import SelectCountry from './ModalElements/SelectCountry';
import UploadProfilePicture from './ModalElements/UploadProfilePicture';

const EditProfile = () => {
    const { API_LINK, email, showEditProfileModal, setShowEditProfileModal, usersID, dob, setDob,
        gender, setGender, firstname, setFirstname, lastname, setLastname, phoneNumber, setPhoneNumber,
        deliveryAddress, setDeliveryAddress, postcode, setPostcode, weight, setWeight,
        height, setHeight, instagram, setInstagram, x, setX, facebook, setFacebook, getCompetitorsInfo,
        deliveryAddressTwo, city, setDeliveryAddressTwo, setCity, country
    } = useContext(Context);
    const [viewSetCountry, setViewSetCountry] = useState(false);

    const handleClose = () => {
        setShowEditProfileModal(false);
        getCompetitorsInfo();
        setTimeout(() => {
            setViewSetCountry(false);
        }, 500);
    };

    useEffect(() => {
        getCompetitorsInfo();
    }, [getCompetitorsInfo]);

    const updateUser = async () => {
        const userInfo = {
            firstname, lastname, phoneNumber, deliveryAddress, deliveryAddressTwo, postcode, city,
            weight, height, instagram, x, facebook, dob, gender, country
        }
        const response = await fetch(API_LINK + '/update-users-profile', {
            method: 'POST',
            body: JSON.stringify({ userID: usersID, userInfo }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                handleClose();
            });
        }
    }

    const handleChangeValue = (event) => {
        let type = event.target.name;
        let value = event.target.value;
        switch (type) {
            case 'firstname':
                setFirstname(value);
                break;
            case 'lastname':
                setLastname(value);
                break;
            case 'phone-number':
                setPhoneNumber(value);
                break;
            case 'delivery-address':
                setDeliveryAddress(value);
                break;
            case 'delivery-address-two':
                setDeliveryAddressTwo(value);
                break
            case 'city':
                setCity(value);
                break;
            case 'postcode':
                setPostcode(value);
                break;
            case 'weight':
                setWeight(value);
                break;
            case 'height':
                setHeight(value);
                break;
            case 'instagram':
                setInstagram(value);
                break;
            case 'x':
                setX(value);
                break;
            case 'facebook':
                setFacebook(value);
                break;
            default:
                break;
        }
    }

    const handleSelectGender = async (eventGender) => {
        setGender(eventGender);
        await fetch(API_LINK + '/update-users-gender', {
            method: 'POST',
            body: JSON.stringify({
                userID: usersID, gender: eventGender
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
    };

    return (
        <Modal show={showEditProfileModal} onHide={handleClose} className="modal-dark">
            <Modal.Header closeButton>
                <Modal.Title>
                    <>
                        <span className="glow-text" style={{
                            fontFamily: 'Neon', color: '#26ffe2', fontSize: '2em', lineHeight: '0.6em'
                        }}>
                            {viewSetCountry ? 'Select Country' : 'Competitor Profile'}
                        </span>
                        <div style={{ fontSize: '0.7em', width: '100%' }}>
                            <span style={{ color: '#bbb' }}>#XXXXX</span> {email}
                        </div>
                    </>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {viewSetCountry ?
                    <SelectCountry setViewSetCountry={setViewSetCountry} />
                    :
                    <>
                        <Row style={{ marginBottom: '30px' }}>
                            <Col></Col>
                            <Col style={{ textAlign: 'center' }}>
                                <UploadProfilePicture />
                            </Col>
                            <Col></Col>
                        </Row>
                        <CompulsoryProfileInfo dob={dob} setDob={setDob} gender={gender} firstname={firstname}
                            lastname={lastname} phoneNumber={phoneNumber} deliveryAddress={deliveryAddress} postcode={postcode}
                            city={city} deliveryAddressTwo={deliveryAddressTwo}
                            handleChangeValue={handleChangeValue} handleSelectGender={handleSelectGender}
                            setViewSetCountry={setViewSetCountry}
                        />

                        <Row style={{ marginBottom: '20px' }}>
                            <Col>
                                <div>Weight (kg)</div>
                                <Form.Control type="text" placeholder="Weight" value={weight || ''} name="weight"
                                    onChange={handleChangeValue} />
                            </Col>
                            <Col>
                                <div>Height (cm)</div>
                                <Form.Control type="text" placeholder="Height" value={height || ''} name="height"
                                    onChange={handleChangeValue} />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '10px' }}>
                            <Col>
                                <div>Instagram</div>
                                <Form.Control type="text" placeholder="Instagram" value={instagram || ''} name="instagram"
                                    onChange={handleChangeValue} />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '10px' }}>
                            <Col>
                                <div>X</div>
                                <Form.Control type="text" placeholder="X" value={x || ''} name="x"
                                    onChange={handleChangeValue} />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '10px' }}>
                            <Col>
                                <div>Facebook</div>
                                <Form.Control type="text" placeholder="Facebook" value={facebook || ''} name="facebook"
                                    onChange={handleChangeValue} />
                            </Col>
                        </Row>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <button className="grey-glow-button" onClick={handleClose}>
                    Close
                </button>
                <button className="green-glow-button" onClick={updateUser}>
                    Save Changes
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditProfile