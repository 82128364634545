import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMemo } from 'react';

// Import JSON language files
import English from './en.json';
import Spanish from './es.json';
import French from './fr.json';
import German from './de.json';
import Dutch from './nl.json';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    // Initialize state to store the current language key
    const [languageKey, setLanguageKey] = useState('en');  // Default to English

    // Object mapping language keys to JSON language data
    const languages = useMemo(() => ({
        en: English,
        es: Spanish,
        fr: French,
        de: German,
        nl: Dutch,
    }), []);

    useEffect(() => {
        const locale = navigator.language.split('-')[0];  // Get the language part (e.g. 'en', 'es')
        // Set the language based on the browser's locale

        if (languages[locale]) {
            setLanguageKey(locale);  // Set language from browser locale
        } else {
            setLanguageKey('en');  // Default to English if the language is not supported
        }
    }, [languages]);

    const changeLanguage = (lang) => {
        if (languages[lang]) {
            setLanguageKey(lang);  // Update language when user selects a new language
        }
    };

    return (
        <LanguageContext.Provider value={{
            language: languages[languageKey], changeLanguage
        }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => useContext(LanguageContext);
