import React, { useContext, useState } from 'react'
import { Col, Spinner } from 'react-bootstrap'
import { Context } from '../../../lib/Context';
import UserImage from '../../Pages/PageElements/UserImage';

const UploadProfilePicture = () => {
    const { API_LINK, usersID, profilePicture, setProfilePicture } = useContext(Context);
    const [savingPhoto, setSavingPhoto] = useState(false);

    const saveData = async (images) => {
        const response = await fetch(API_LINK + '/save-user-photo', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                images: images[0], userID: usersID
            }),
            headers: { 'Content-Type': 'application/json' }
        })
        const imageURL = await response.json();
        setProfilePicture(imageURL);
        setSavingPhoto(false);
    }

    const uploadImages = async (files) => {
        if (files.length === 0) {
            alert('Please select an image!')
            return;
        }
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('images', files[i]);
        }

        const response = await fetch(process.env.REACT_APP_BACKEND_LINK + '/image/upload', {
            method: 'POST',
            body: formData
        });
        const data = await response.json();
        let images = [];
        const savedFiles = data.files;
        for (let i = 0; i < savedFiles.length; i++) {
            images.push(savedFiles[i].key);
        }
        await saveData(images);
    }

    const onImageChange = (event) => {
        setSavingPhoto(true);
        const submittingFiles = event.target.files;
        uploadImages(submittingFiles);
    }

    return (
        <Col>
            {savingPhoto ?
                <div style={{ width: '140px', height: '140px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner />
                </div>
                :
                <>
                    <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                        <UserImage width={'140px'} height={'140px'} profilePicture={profilePicture} />
                    </label>
                    <input
                        id="file-upload"
                        onChange={onImageChange}
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                    />
                </>
            }

        </Col>
    )
}

export default UploadProfilePicture