import React from 'react'
import Form from 'react-bootstrap/Form';
import { useTranslation } from '../../../hooks/useTranslation';

const AuthBody = (props) => {
    const {
        buildInputs, confirmLoginEmail, emailsMatch, password,
        confirmPassword, passwordsMatch, validEmail, userExists, toggleForgotPassword
    } = props;

    const { t } = useTranslation();


    return (
        <>
            {validEmail && !userExists &&
                <>
                    <p>
                        {t("auth.new.account")}
                        {/* Looks like we need to create a new account for you. It is important that you have
                        entered in the correct information so please enter in your email again to confirm it is correct. */}
                    </p>
                    <Form.Group className="mb-3" controlId="confirm-login-email">
                        <Form.Control type="email" name="confirm-login-email" value={confirmLoginEmail}
                            onInput={buildInputs} placeholder={t("auth.email.placeholder")} />
                    </Form.Group>
                    {emailsMatch &&
                        <>
                            <p>
                                {t("auth.emails.match")}
                                {/* Nice one! Now please enter in your desired password. */}
                            </p>
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Control type="password" name="password" value={password}
                                    onInput={buildInputs} placeholder={t("auth.password")} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="confirm-password">
                                <Form.Control type="password" name="confirm-password" value={confirmPassword}
                                    onInput={buildInputs} placeholder={t("auth.password.confirm")} />
                            </Form.Group>
                        </>
                    }
                    {passwordsMatch && t("auth.passwords.match")}
                </>
            }
            {validEmail && userExists &&
                <>
                    <Form.Group className="mb-3" controlId="login-password">
                        <Form.Control type="password" name="password" value={password} onInput={buildInputs}
                            placeholder={t("auth.password")} />
                    </Form.Group>
                    <p onClick={toggleForgotPassword} style={{
                        color: '#26ffe2', width: '100%', textAlign: 'center', cursor: 'pointer'
                    }}>
                        {t("auth.button.forgot.password")}
                        {/* Forgot Password? */}
                    </p>
                </>
            }
        </>
    )
}

export default AuthBody