import React from 'react'
import { Form } from 'react-bootstrap';
import { useTranslation } from '../../../hooks/useTranslation';

const ForgotPassword = (props) => {
    const { toggleForgotPassword, codeGenerated, buildInputs, validationCode, confirmPassword, password, codeValid } = props;
    const { t } = useTranslation();
    return (
        <>
            {!codeGenerated ?
                <p>
                    {t("forgot.password.intro")}
                    {/* We will then send you an email with a 6-digit code to confirm that it is you making the request. */}
                </p>
                :
                !codeValid &&
                <Form.Group className="mb-3" controlId="validation-code">
                    <Form.Control type="text" name="validation-code" value={validationCode}
                        onInput={buildInputs} placeholder={t("forgot.password.code.input")} />
                </Form.Group>
            }
            {codeValid &&
                <>
                    <p>Nice one! Now please enter in your desired password.</p>
                    <Form.Group className="mb-3" controlId="password">
                        <Form.Control type="password" name="password" value={password}
                            onInput={buildInputs} placeholder={t("auth.password")} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="confirm-password">
                        <Form.Control type="password" name="confirm-password" value={confirmPassword}
                            onInput={buildInputs} placeholder={t("auth.password.confirm")} />
                    </Form.Group>
                </>
            }


            <p onClick={toggleForgotPassword} style={{
                color: '#26ffe2', width: '100%', textAlign: 'center', cursor: 'pointer'
            }}>
                {t("forgot.password.button")}
                {/* Login / Register */}
            </p>

        </>
    )
}

export default ForgotPassword;