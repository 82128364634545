import { useContext, useState } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import validateEmail from '../../lib/Functions/validateEmail';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Context } from '../../lib/Context';
import '../../lib/css/DarkModal.css';
import { useNavigate } from 'react-router-dom';
import AuthBody from './ModalElements/AuthBody';
import ForgotPassword from './ModalElements/ForgotPassword';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

function AuthModal(props) {
    const { showAuth, setShowAuth } = props;
    const { API_LINK, setFirstname, setLastname, setEmail, setProfilePicture, setEvents, setType, setUsersID,
        handleCheckUserDetails, setCountry
    } = useContext(Context);
    const [loginEmail, setLoginEmail] = useState('');
    const [confirmLoginEmail, setConfirmLoginEmail] = useState('');
    const [emailsMatch, setEmailsMatch] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [codeGenerated, setCodeGenerated] = useState(false);
    const [validationCode, setValidationCode] = useState('');
    const [codeValid, setCodeValid] = useState(false);


    const [userExists, setUserExists] = useState(false);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const login = async () => {
        const response = await fetch(API_LINK + '/login', {
            method: 'POST',
            body: JSON.stringify({ password, email: loginEmail }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                setFirstname(data.firstname);
                setEmail(data.email);
                setLastname(data.lastname);
                setProfilePicture(data.profilePicture);
                setEvents(data.events);
                setType(data.type);
                setUsersID(data.usersID);
                setCountry(data.country);
                closeModal();
                handleCheckUserDetails({ userID: data.usersID, events: data.events });
                navigate('/events');
                // Check to see if the user is any upcoming events
                // Then display a popup to get them to enter in their weights for the event
            });
        } else {
            setErrorMessage(t("auth.email.error"))
        }
    }

    // This builds the input data from the form inputs.
    const buildInputs = (event) => {
        const value = event.target.value;
        const type = event.target.name;
        switch (type) {
            case 'password':
                setPassword(value);
                confirmPassword === value && confirmPassword.length > 0 ? setPasswordsMatch(true) : setPasswordsMatch(false);
                break;
            case 'confirm-password':
                setConfirmPassword(value);
                password === value && password.length > 0 ? setPasswordsMatch(true) : setPasswordsMatch(false);
                break;
            case 'confirm-login-email':
                setConfirmLoginEmail(value);
                loginEmail.toLocaleLowerCase() === value.toLocaleLowerCase() ? setEmailsMatch(true) : setEmailsMatch(false);
                break;
            case 'validation-code':
                setValidationCode(value);
                break;
            default:
                break;
        }
    }




    const setNewPassword = async () => {
        const response = await fetch(API_LINK + '/set-new-password', {
            method: 'POST',
            body: JSON.stringify({ password, email: loginEmail }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async () => {
                await login();
            });
        }
    }

    const closeModal = () => {
        setShowAuth(false);
        setLoginEmail('');
        setPassword('');
        setPasswordsMatch(false);
        setConfirmPassword('');
        setValidEmail(false);
        setErrorMessage('');
    }

    const toggleForgotPassword = () => {
        // TODO: Figure this out
        setShowForgotPassword(prev => !prev);
    }

    // Checks to see if an email is valid and if it is retrieves the information from the backend.
    const buildEmail = async (event) => {
        setValidEmail(false);
        const userInput = event.target.value.toLowerCase();
        const isValid = validateEmail(userInput);
        if (isValid) {
            const response = await fetch(API_LINK + '/check-users-email', {
                method: 'POST',
                body: JSON.stringify({ email: userInput }),
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });
            if (response.ok) {
                response.json().then(async data => {
                    setUserExists(data);
                    setValidEmail(true);
                });
            }
        }
        setLoginEmail(userInput);
    }

    const generateValidationCode = async () => {
        setCodeGenerated(true);
        const response = await fetch(API_LINK + '/generate-validation-code', {
            method: 'POST',
            body: JSON.stringify({ email: loginEmail }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        });
        if (response.ok) {
            response.json().then(async () => {
                setCodeGenerated(true);
            });
        }
    }

    const submitValidationCode = async () => {
        console.log('Generating validation code');
        const response = await fetch(API_LINK + '/check-validation-code', {
            method: 'POST',
            body: JSON.stringify({ email: loginEmail, code: validationCode }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        });
        if (response.ok) {
            response.json().then(async (data) => {
                if (data?.message !== 'OK') {
                    setErrorMessage(data.message);
                } else {
                    setErrorMessage('');
                    setCodeValid(true);
                }
            });
        }
    }

    return (
        <Modal show={showAuth} onHide={closeModal} className="modal-dark">
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("auth.modal.title")}
                    {/* Login or Register */}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row style={{ marginBottom: '30px' }}>
                    <Form>
                        {!showForgotPassword ?
                            <p>{t("auth.email")}</p>
                            // Please enter the email you wish to login or register with.
                            :
                            <p>{t("auth.email.alt")}</p>
                            // Please enter the email you registered with.
                        }

                        <Form.Group className="mb-3" controlId="login-email">
                            <Form.Control
                                type="email"
                                value={loginEmail}
                                onInput={buildEmail}
                                placeholder={t('auth.email.placeholder')} />
                        </Form.Group>

                        {!showForgotPassword && !validEmail &&
                            t("auth.forgot.password")
                            // If you've forgotten your password then please enter your email in.
                        }

                        {
                            !showForgotPassword ?
                                <AuthBody
                                    loginEmail={loginEmail}
                                    buildInputs={buildInputs}
                                    confirmLoginEmail={confirmLoginEmail}
                                    emailsMatch={emailsMatch}
                                    password={password}
                                    confirmPassword={confirmPassword}
                                    passwordsMatch={passwordsMatch}
                                    validEmail={validEmail}
                                    userExists={userExists}
                                    codeValid={codeValid}
                                    toggleForgotPassword={toggleForgotPassword}
                                    validateEmail={validateEmail}
                                    setValidEmail={setValidEmail}
                                    setUserExists={setUserExists}
                                    setLoginEmail={setLoginEmail}

                                />
                                :
                                <ForgotPassword
                                    toggleForgotPassword={toggleForgotPassword}
                                    codeGenerated={codeGenerated}
                                    buildInputs={buildInputs}
                                    validationCode={validationCode}
                                    confirmPassword={confirmPassword}
                                    password={password}
                                    codeValid={codeValid}
                                />
                        }
                    </Form>
                </Row>
                {errorMessage &&
                    <Alert variant="danger" style={{ width: '100%', textAlign: 'center' }}>
                        {errorMessage}
                    </Alert>
                }
            </Modal.Body>
            {!showForgotPassword ?
                <>
                    {validEmail &&
                        <Modal.Footer>
                            {validEmail && userExists &&
                                <button className="green-glow-button" onClick={login}>
                                    {t("auth.buttons.login")}
                                    {/* Login */}
                                </button>
                            }
                            {validEmail && !userExists && passwordsMatch &&
                                <button className="green-glow-button" onClick={setNewPassword}>
                                    {t("auth.buttons.submit.password")}
                                    {/* Submit New Password */}
                                </button>
                            }
                            {validEmail && !userExists && !passwordsMatch && !emailsMatch &&
                                <Alert variant="danger" style={{ width: '100%', textAlign: 'center' }}>
                                    {t("auth.match.email")}
                                    {/* Please enter in a matching email. */}
                                </Alert>
                            }
                            {validEmail && !userExists && !passwordsMatch && emailsMatch &&
                                <Alert variant="success" style={{ width: '100%', textAlign: 'center' }}>
                                    {t("auth.enter.password")}
                                    {/* Please input your desired password. */}
                                </Alert>
                            }
                        </Modal.Footer>
                    }
                </>
                :
                !codeGenerated ?
                    <Modal.Footer>
                        <button className="green-glow-button" onClick={generateValidationCode}>
                            {t("auth.send.code")} <FontAwesomeIcon icon={faPaperPlane} style={{
                                marginLeft: '7px'
                            }} />
                            {/* Send Me The 6-Digit Code  */}
                        </button>
                    </Modal.Footer>
                    : !codeValid && !passwordsMatch ?
                        <Modal.Footer>
                            <button className="green-glow-button" onClick={submitValidationCode}>
                                {t("auth.submit.code")}
                                {/* Submit 6-Digit Code */}
                            </button>
                        </Modal.Footer>
                        :
                        <Modal.Footer>
                            <button className="green-glow-button" onClick={setNewPassword}>
                                {t("auth.submit.password")}
                                {/* Submit New Password */}
                            </button>
                        </Modal.Footer>
            }
        </Modal>
    );
}

export default AuthModal;