import Modal from 'react-bootstrap/Modal';

function ProofPhotoModal(props) {
    const { showPhotoModal, togglePhotoModal, exerciseProofs, slug, photoURL, pictureInfo } = props;


    return (
        <>
            <Modal show={showPhotoModal} onHide={togglePhotoModal} size="xl" className="modal-dark">
                <Modal.Header closeButton>
                    <Modal.Title>{pictureInfo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={exerciseProofs[slug]} alt={photoURL}
                        style={{
                            width: '100%', height: 'auto'
                        }} />
                </Modal.Body>
                <Modal.Footer>
                    <button className="green-glow-button" onClick={togglePhotoModal} style={{ float: 'left' }}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ProofPhotoModal;