import React, { useContext, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { Context } from '../../../../lib/Context';
import PaymentForm from './PaymentForm';

const StripePayment = (props) => {
    // eslint-disable-next-line
    const { price, seasonNumber, handleClose, setProcessingPayment, eventName, eventType, eventID, isLiveEvent } = props;
    const { API_LINK, usersID } = useContext(Context)
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");


    useEffect(() => {
        fetch(API_LINK + '/config', {
            credentials: 'include',
            method: 'GET'
        }).then(response => {
            response.json().then(data => {
                setStripePromise(loadStripe(data.publishableKey));
            })
        })
    }, [API_LINK]);

    useEffect(() => {
        fetch(API_LINK + '/create-payment-intent', {
            method: 'POST',
            body: JSON.stringify({ price, userID: usersID, eventName, eventID, isLiveEvent }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(async (response) => {
            const { clientSecret } = await response.json()
            setClientSecret(clientSecret);
        });
    }, [API_LINK, price, usersID, eventName, eventID, isLiveEvent]);

    const appearance = {
        theme: 'night',
        variables: {
            colorText: '#ffffff',
            colorPrimary: '#26ffe2',
            colorBackground: '#2e227a',
            fontFamily: 'Arial, sans-serif',
            borderRadius: '10px'
        }
    };

    const options = {
        clientSecret,
        appearance,
    };


    return (
        stripePromise && clientSecret &&
        <Elements stripe={stripePromise} options={options}>
            <PaymentForm handleClose={handleClose} eventID={eventID} isLiveEvent={isLiveEvent} />
        </Elements>
    )
}

export default StripePayment