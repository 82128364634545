import React from 'react';
import HarryPalmer from '../../../lib/Images/competitor-images/HarryPalmer.jpg';
import AbbieDickens from '../../../lib/Images/competitor-images/AbbieDickens.jpg';
import AndyHT from '../../../lib/Images/competitor-images/AndyHT.JPG';
import ChrisSearle from '../../../lib/Images/competitor-images/ChrisSearle.jpg';
import FredWebb from '../../../lib/Images/competitor-images/FredWebb.JPG';
import GeorgeInnes from '../../../lib/Images/competitor-images/GeorgeInnes.jpg';
import JordanFisher from '../../../lib/Images/competitor-images/JordanFisher.jpg';
import LiamLLoyd from '../../../lib/Images/competitor-images/LiamLLoyd.JPG';
import LinLaw from '../../../lib/Images/competitor-images/LinLaw.png';
import LoueseRichmond from '../../../lib/Images/competitor-images/LoueseRichmond.jpg';
import LucyFyfe from '../../../lib/Images/competitor-images/LucyFyfe.jpg';
import MarkBowman from '../../../lib/Images/competitor-images/MarkBowman.jpg';
import MatthewCostello from '../../../lib/Images/competitor-images/MatthewCostello.png';
import NickOlsen from '../../../lib/Images/competitor-images/NickOlsen.jpg';
import PhilipSimpson from '../../../lib/Images/competitor-images/PhilipSimpson.jpg';
import SamWordley from '../../../lib/Images/competitor-images/SamWordley.JPG';
import StevieFyfe from '../../../lib/Images/competitor-images/StevieFyfe.jpg';
import TreyRichmond from '../../../lib/Images/competitor-images/TreyRichmond.png';

import '../../../lib/css/HomePageLeaderboard.css';
import HomePageCompetitor from './HomePageCompetitor';


const HomePageLeaderboard = (props) => {
    const { raceType } = props;


    let competitors = [
        {
            firstname: 'Harry',
            lastname: 'Palmer',
            isPro: true,
            raceType: 'Standard',
            gender: 'Male',
            country: ['gb', 'United Kingdom'],
            image: HarryPalmer
        },
        {
            firstname: 'Andrew',
            lastname: 'Horsfall Turner',
            isPro: true,
            raceType: 'Sprint',
            gender: 'Male',
            country: ['gb', 'United Kingdom'],
            image: AndyHT
        },
        {
            firstname: 'Sam',
            lastname: 'Wordley',
            isPro: true,
            raceType: 'Standard',
            gender: 'Male',
            country: ['gb', 'United Kingdom'],
            image: SamWordley
        },
        {
            firstname: 'Liam',
            lastname: 'Lloyd',
            isPro: true,
            raceType: 'Sprint',
            gender: 'Male',
            country: ['gb', 'United Kingdom'],
            image: LiamLLoyd
        },
        {
            firstname: 'Freddie',
            lastname: 'Webb',
            isPro: true,
            raceType: 'Standard',
            gender: 'Male',
            country: ['gb', 'United Kingdom'],
            image: FredWebb
        },
        {
            firstname: 'Jordan',
            lastname: 'Fisher',
            isPro: false,
            raceType: 'Sprint',
            gender: 'Male',
            country: ['gb', 'United Kingdom'],
            image: JordanFisher
        },
        {
            firstname: 'Chris',
            lastname: 'Searle',
            isPro: false,
            raceType: 'Sprint',
            gender: 'Male',
            country: ['au', 'Australia'],
            image: ChrisSearle
        },
        {
            firstname: 'Abbie',
            lastname: 'Dickens',
            isPro: false,
            raceType: 'Sprint',
            gender: 'Female',
            country: ['au', 'Australia'],
            image: AbbieDickens
        },
        {
            firstname: 'George',
            lastname: 'Innes',
            isPro: false,
            raceType: 'Standard',
            gender: 'Male',
            country: ['gb', 'United Kingdom'],
            image: GeorgeInnes
        },
        {
            firstname: 'Mark',
            lastname: 'Bowman',
            isPro: false,
            raceType: 'Standard',
            gender: 'Male',
            country: ['gb', 'United Kingdom'],
            image: MarkBowman
        },
        {
            firstname: 'Lin',
            lastname: 'Law',
            isPro: false,
            raceType: 'Standard',
            gender: 'Female',
            country: ['cn', 'China'],
            image: LinLaw
        },
        {
            firstname: 'Matthew',
            lastname: 'Costello',
            isPro: false,
            raceType: 'Sprint',
            gender: 'Male',
            country: ['gb', 'United Kingdom'],
            image: MatthewCostello
        },
        {
            firstname: 'Louese',
            lastname: 'Richmond',
            isPro: false,
            raceType: 'Sprint',
            gender: 'Female',
            country: ['us', 'United States'],
            image: LoueseRichmond
        },
        {
            firstname: 'Trey',
            lastname: 'Richmond',
            isPro: false,
            raceType: 'Sprint',
            gender: 'Male',
            country: ['us', 'United States'],
            image: TreyRichmond
        },
        {
            firstname: 'Harry',
            lastname: 'Geaves',
            isPro: false,
            raceType: 'Standard',
            gender: 'Male',
            country: ['no', 'Norway'],
            image: ''
        },
        {
            firstname: 'Philip',
            lastname: 'Simpson',
            isPro: false,
            raceType: 'Sprint',
            gender: 'Male',
            country: ['au', 'Australia'],
            image: PhilipSimpson
        },
        {
            firstname: 'Nick',
            lastname: 'Oleksa',
            isPro: false,
            raceType: 'Standard',
            gender: 'Male',
            country: ['pl', 'Poland'],
            image: NickOlsen
        },
        {
            firstname: 'Lucy',
            lastname: 'Fyfe',
            isPro: false,
            raceType: 'Standard',
            gender: 'Female',
            country: ['gb', 'United Kingdom'],
            image: LucyFyfe
        },
        {
            firstname: 'Steven',
            lastname: 'Fyfe',
            isPro: false,
            raceType: 'Standard',
            gender: 'Male',
            country: ['gb', 'United Kingdom'],
            image: StevieFyfe
        }
    ];



    return (
        <div style={{ overflowX: 'auto' }}>
            <table style={{ width: '100%', marginBottom: '30px' }} className="responsive-table">
                <thead>
                    <tr>
                        <th style={{ padding: '20px' }}>
                            Name
                        </th>
                        {/* Hide this if user is on mobile */}
                        <th className="mobile-hide" style={{ padding: '20px', textAlign: 'center', width: '20%' }}>
                            Swim
                            <div className="exercise-type">{
                                raceType === 'Standard' ? '1.5km' : '0.3km'
                            }</div>
                        </th>
                        <th className="mobile-hide" style={{ padding: '20px', textAlign: 'center', width: '20%' }}>
                            Bike
                            <div className="exercise-type">{
                                raceType === 'Standard' ? '40km' : '20km'
                            }</div>
                        </th>
                        <th className="mobile-hide" style={{ padding: '20px', textAlign: 'center', width: '20%' }}>
                            Run
                            <div className="exercise-type">{
                                raceType === 'Standard' ? '10km' : '5km'
                            }</div>
                        </th>
                        {/* End Hide this if user is on mobile */}
                        <th style={{ padding: '20px', textAlign: 'right' }}>
                            <span style={{ marginRight: '40px' }}>Total</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {competitors.map((competitor, index) => (
                        competitor.raceType === raceType &&
                        <HomePageCompetitor
                            key={`display-item-${index}`}
                            competitor={competitor}
                            index={index}
                            raceType={raceType}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default HomePageLeaderboard